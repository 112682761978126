#container > * { position:relative; }



body{
  padding: 0;
  margin: 0;
}
.mainText{
  font-size: 2.5em;
  width: 65%;
  margin: 0;
  padding: 0;
  /* text-align: center; */
  font-family: "ptMono";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.mainText-Mobile{
  font-size: 2.0em;
  width: 95%;
  margin: 0;
  padding: 0;
  /* text-align: center; */
  font-family: "ptMono";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}


img {
  position: absolute;
  visibility: hidden;

  /* object-fit: cover;
  transform: translateX(-50%) translateY(-50%);
  top: 25%;
  left: 20%;
  z-index: -10;
  opacity: 0; */

  /* pointer-events: none; */
}

.visibleImage {
  position: relative !important;
  visibility: visible !important;
}

.visibleImageModal {
  position: relative !important;
  visibility: visible !important;

  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 900;


  box-shadow: 24;
}


canvas {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  pointer-events: none;

}

.fontStyle{
  margin-left: 100px;
  font-size: 100px;
  margin-bottom: 90px;
  /* color: #f9cdcd */
}

.fontStyleMobile{
  margin-left: 10px;
  font-size: 3em;
  margin-bottom: 20px;
  /* color: #f9cdcd */
}

#projects {
  scroll-margin-bottom: 90px;
}