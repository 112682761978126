@font-face {
  font-family: 'interRegular';
  src: url('./font/Inter-Regular.ttf');
}
@font-face {
  font-family: 'interBlack';
  src: url('./font/Inter-Black.ttf');
}
@font-face {
  font-family: 'interMedium';
  src: url('./font/Inter-Medium.ttf');
}
@font-face {
  font-family: 'interLight';
  src: url('./font/Inter-Light.ttf');
}
@font-face {
  font-family: 'interThin';
  src: url('./font/Inter-Thin.ttf');
}
@font-face {
  font-family: 'interSemiBold';
  src: url('./font/Inter-SemiBold.ttf');
}
@font-face {
  font-family: 'ptMono';
  src: url('./font/PTMono-Regular.ttf');
}

*{
  font-family: "interRegular";
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
