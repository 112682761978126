.alex-logo{
  font-size: "5px";
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

  }
  
  li {
    float: left;
  }
  
  li a {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  .grid-container-nav {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr
}

.grid-container-nav-mobile {
  display: grid;
  grid-template-columns: 3fr 0fr 3fr
}

